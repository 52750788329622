import React, {Component} from "react";
import "../css/Contatti.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faMapPin } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

class Contatti extends Component{
    render(){
        (function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
        return(
            <div id="sec-3" className="container">
            <h3 className="title">BOLCAM SRL</h3>
            <ul>
            <li className="contatti"><FontAwesomeIcon icon={faPhone}/>&nbsp;0432 716017</li>
            <li className="contatti"> <a href="mailto:bolcamsedie@gmail.com"><FontAwesomeIcon icon={faEnvelope}/>&nbsp;bolcamsedie@gmail.com </a></li>
            <li className="contatti"> <a href="https://www.google.it/maps/place/Bol.Cam+Sedie+Di+Bolzicco+Andrea+E+Campanella+Samuele+Snc/@46.0222126,13.3926742,365m/data=!3m1!1e3!4m6!3m5!1s0x477bb2c828d436dd:0x2eb3e918be88df14!8m2!3d46.0224451!4d13.3924637!16s%2Fg%2F1thcr57h?entry=ttu"><FontAwesomeIcon icon={faMapPin}/>&nbsp;Via dei Prati, 5, 33044 Manzano UD </a></li>
            <li className="privacy"> <a href="https://www.iubenda.com/privacy-policy/82030993" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a> <a href="https://www.iubenda.com/privacy-policy/82030993/cookie-policy" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a></li>
            </ul>
            </div>
        );
    }
}

export default Contatti;